/* 
  Color Theme 
    Background color  #023246
    Nav Titles        #287094
    Headings          #F6F6F6
    Content           #D4D4CE
*/
.App {
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  background-color: rgb(2, 16, 16);
}

h1 {
  color: white;
}
/* *******************************NAVBAR************************************ */
.nav-bar-container {
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.247);
  height: 3.5em;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 0;
  transition: top 0.5s; /* Transition effect when sliding up/down */
  width: 100%;
  z-index: 1;
  
  -webkit-box-shadow: 0 10px 5px 0 rgba(0,0,0,.1); /* Safari/Chrome/ Webkits*/
  -moz-box-shadow:    0 10px 5px 0 rgba(0,0,0,.1); /* Firefox, other Gecko */
  box-shadow:         0 10px 5px 0 rgba(0,0,0,.1); /* Opera/IE 8+ */
}

.navBar {
  backdrop-filter: blur(20px);
  display: flex;
  height: 3.5em;
  list-style-type: none;
  position: relative;
  margin: auto;
  width: 100%;
}

.logo {
  position: absolute;
  top: .3em;
  width: 3em;
}

.nav-right {
  position: absolute;
  right: 3em;
}

.navBar li {
  display: inline;
  margin: 0 1vw 0;
}

.navBar a {
  color: white;
  display: inline-block;
  padding: 1em;
  text-align: center;
  text-decoration: none;
}

.navBar a:hover {
  color: #1fab89;
}

.resume {
  border: 1px solid red;
  border-radius: 5px;
  margin: .6em 0;
}

.resume a {
  padding: 0;
}

@media screen and (max-width:800px) {
  .nav-section {
    position: absolute;
    visibility: hidden;
  }
}

/* *******************************LANDING PAGE****************************** */
.landing-page-container {
  background-image: url("./components/images/landingPage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  margin: 0;
}

.landing-page {
  margin: auto;
  padding-top: 24em;
  width: 80%;
  text-align: center;
}

.landing-page-tags {
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.landing-page h3 {
  color: #9df3c4;
  font-size: 2em;
}

.landing-page h1 {
  color: #d7fbe8;
  font-size: 7em;
}

.landing-page h2 {
  color: #9df3c4;
  font-size: 3em;
}

.landing-page p {
  color: #62d2a2;
  font-size: 1.3em;
  margin: 0;
  width: 20em;
}

@media screen and (max-width: 540px){
  .landing-page {
    padding-top: 16em;
  }
  .landing-page h3 {
    font-size: 1em;
  }
  .landing-page h1 {
    font-size: 3.5em;
  }
  .landing-page h2 {
    font-size: 1.5em;
  }
  .landing-page p {
    font-size: 1em;
  }
}

/* *******************************ABOUT ME PAGE***************************** */
.about-me-container {
  padding: 5em auto;
  margin: 0 auto;
  text-align: left;
  width: 80%;
}

.about-me-container h1 {
  color: #9df3c4;
  font-size: 2em;
}

.about-me-container p {
  color: #62d2a2;
  font-size: 1em;
}

.about-me {
  margin: 0;
}

.about-me a:link {
  color: #62d2a2;
}

.about-me a:visited {
  color: #62d2a2;
}

/* *******************************SKILLS PAGE******************************* */
.skills-container {
  margin: 7em auto;
  padding: 5vh auto;
  width: 80%;
}

.skills {
  background-color: #d7fbe8;
  border-radius: 20px;
  padding: 2em;
  margin: 0 auto;
  max-width: fit-content;
}

.skills h1 {
  color: #1fab89;
}

.skills h3 {
  color: #1fab89;
}

.skills p {
  color: #17b978;
  min-width: 10em;
}

.skill-table-container {
  height: 25em;
  width: 100%;
  text-align: center;
}

.FrontEndDiv {
  display: inline-block;
  height: 21em;
  margin: 0 auto;
  min-width: 8em;
  padding: 1em;
}

.BackEndDiv {
  border-left: 1px solid #62d2a2;
  border-right: 1px solid #62d2a2;
  display: inline-block;
  height: 21em;
  margin: 0 auto;
  min-width: 8em;
  padding: 1em;
}

.ConceptDiv {
  display: inline-block;
  height: 21em;
  margin: 0 auto;
  min-width: 8em;
  padding: 1em;
}

@media screen and (max-width:860px) {
  .FrontEndDiv, .BackEndDiv, .ConceptDiv {
    width:85%;
  }
  .BackEndDiv{
    border: none;
  }
  .skills {
    height: 1250px;
  }
}

.skill-icon {
  filter: invert(49%) sepia(99%) saturate(341%) hue-rotate(115deg) brightness(92%) contrast(89%);
  width: 4em;
}

/* *******************************PROJECT PAGE****************************** */
.project-container {
  margin: auto;
  max-width: 80em;
  width: 90%;
}

.project-container h1 {
  color: #9df3c4;
}

.project {
  background-color: #d7fbe8;
  border-radius: 3px;
  box-shadow: 0 3px 8px rgb(134, 134, 134);
  display: inline-block;
  height: 17em;
  margin: 8px;
  overflow: hidden;
  position: relative;
  padding: 1.5em;
  top: 0;
  transition: top ease 0.2s;
  width: 17em; 
}

.project:hover {
  top: -5px;
}

.project-folder-icon {
  filter: invert(49%) sepia(99%) saturate(341%) hue-rotate(115deg) brightness(92%) contrast(89%);
  float: left;
  width: 2em;
}

.project-link {
  filter: invert(80%) sepia(16%) saturate(1117%) hue-rotate(100deg) brightness(90%) contrast(88%);
  float: right;
  padding: .3em;
  width: 1.4em;
}

/*
  For future tint adjustments
  https://codepen.io/sosuke/pen/Pjoqqp
*/
.project-link:hover {
  filter: invert(47%) sepia(94%) saturate(356%) hue-rotate(115deg) brightness(96%) contrast(90%);
}

.project h3 {
  color: #1fab89;
  display: block;
  font-size: 1.1em;
  margin-top: 2.5em;
  margin-bottom: .5em;
  text-align: left;
}

.project p {
  color: #17b978;
  font-size: 1em;
  text-align: left;
}

.project-tech {
  bottom: 0;
  left: 1em;
  position: absolute;
  text-align: left;
}

/* *******************************CONTACT*********************************** */
.ending{
  background-image: url(./components/images/ending.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-container {
  height: 35vh;
  padding-top: 10em;
}

.contact {
  color: white;
  margin: 0 auto;
  min-width: 20em;
  width: 50%;
}

.contact h1 {
  color: #9df3c4;
}

.contact p {
  color: #62d2a2;
}

.contact button {
  background-color: #d7fbe8;
  border: 2px solid #62d2a2 ;
  cursor: pointer;
  font-size: 1.5em;
  padding: .3em;
  text-align: center;
  
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.contact a {
  color: #1fab89;
  text-decoration: none;
}

.contact button:hover {
  background-color: #62d2a2;
  color: #252A34;
  text-decoration: none;

}

/* *******************************FOOTER************************************ */
footer {
  bottom: 0;
  height: 35vh;
  text-align: center;
  width: 100%;
}

footer h1 {
  color: #d7fbe8;
  margin-top: 0;
  margin-bottom: 0;
}

footer h4 {
  color: #62d2a2;
  margin-top: 0;
  margin-bottom: 0;
}

footer hr {
  width: 30%;
}

.link-img {
  filter: invert(49%) sepia(99%) saturate(341%) hue-rotate(115deg) brightness(92%) contrast(89%);
  margin: 0 1vw;
  width: 3em;
}

footer p {
  color:#9e9e9e;
  font-size: small;
  margin: 0;
}